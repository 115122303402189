var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-10",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('h3',{staticClass:"pageHeadline",attrs:{"t-data":"page-headline"}},[_vm._v("Force Update")])],1)],1),_c('v-snackbar',{attrs:{"width":"500","color":"success200Color","top":"","centered":""},model:{value:(_vm.isCreateSuccess),callback:function ($$v) {_vm.isCreateSuccess=$$v},expression:"isCreateSuccess"}},[_c('div',{staticClass:"d-flex flex-row align-center mt-0"},[_c('v-img',{staticClass:"mt-0 mr-2",class:{
          'snackbar-image': 'isCreateSuccess'
        },attrs:{"src":"/images/snackbar-success.png","aspect-ratio":"1","snackbar-image":""}}),_c('p',{class:{
          'snackbar-message sub-body-regular': 'isCreateSuccess',
          'mb-0': 'isCreateSuccess'
        }},[_vm._v(" Package has been "+_vm._s(_vm.snackTitle)+" successfully. ")])],1)]),_c('v-card',[_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1 custom-table version-table",staticStyle:{"width":"100%"},attrs:{"loading":_vm.isLoading,"options":_vm.options,"headers":_vm.table.headers,"items":_vm.appVersionList,"hide-default-footer":true,"item-key":"id","fixed-header":"","height":_vm.windowSize.y - _vm.heightOptions.header - _vm.heightOptions.tableFooter},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.update_date",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(!!item.update_date ? _vm.dateFmt(item.update_date) : ''))]}},{key:"item.update_by_user",fn:function(ref){
        var item = ref.item;
return [_c('username-status-text',{attrs:{"user":item.update_by_user}})]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"width":"130px"}},[(_vm.hasPermEdit())?_c('v-icon',{staticClass:"px-2 action-icon",on:{"click":function($event){return _vm.rowClick(item)}}},[_vm._v("mdi-pencil-outline")]):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }