<template>
  <v-container class="px-10" fluid>
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <h3 class="pageHeadline" t-data="page-headline">Force Update</h3>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="isCreateSuccess"
      width="500"
      color="success200Color"
      top
      centered
    >
      <div class="d-flex flex-row align-center mt-0">
        <v-img
          src="/images/snackbar-success.png"
          aspect-ratio="1"
          snackbar-image
          class="mt-0 mr-2"
          :class="{
            'snackbar-image': 'isCreateSuccess'
          }"
        ></v-img>
        <p
          :class="{
            'snackbar-message sub-body-regular': 'isCreateSuccess',
            'mb-0': 'isCreateSuccess'
          }"
        >
          Package has been {{ snackTitle }} successfully.
        </p>
      </div>
    </v-snackbar>

    <!-- Data table -->
    <v-card>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          :loading="isLoading"
          :options.sync="options"
          :headers="table.headers"
          :items="appVersionList"
          :hide-default-footer="true"
          class="elevation-1 custom-table version-table"
          item-key="id"
          fixed-header
          style="width: 100%"
          :height="
            windowSize.y - heightOptions.header - heightOptions.tableFooter
          "
        >
          <template v-slot:item.update_date="{item}">{{ !!item.update_date ? dateFmt(item.update_date) : '' }}</template>
          <template v-slot:item.update_by_user="{item}"><username-status-text :user="item.update_by_user" /></template>
          <template v-slot:item.action="{item}">
            <div style="width: 130px">
              <v-icon
                v-if="hasPermEdit()"
                class="px-2 action-icon"
                @click="rowClick(item)"
                >mdi-pencil-outline</v-icon
              >
            </div>
          </template>
        </v-data-table>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import // hasPermision,
'../../helper/helper'
import {convertDate} from '@/helper/helper'
import UsernameStatusText from '@/components/UsernameStatusText'

export default {
  components: {
    UsernameStatusText
  },
  data() {
    return {
      heightOptions: {
        header: 157,
        tableFooter: 59
      },
      windowSize: {
        x: 0,
        y: 0
      },
      isCreateSuccess: false,
      snackTitle: 'created',
      options: {},
      table: {
        headers: [
          {
            text: 'ID',
            align: 'left',
            sortable: true,
            value: 'id',
            width: 88
          },
          {
            text: 'Title',
            align: 'left',
            value: 'device_os',
            sortable: true,
            width: 126
          },
          {
            text: 'Description (English)',
            align: 'left',
            value: 'description_en',
            sortable: true,
            width: 350
          },
          {
            text: 'Description (Thai)',
            align: 'left',
            value: 'description_th',
            sortable: true,
            width: 350
          },
          {
            text: 'Min OS Version',
            align: 'center',
            value: 'device_min_version',
            sortable: true,
            width: 174
          },
          {
            text: 'Current Version',
            align: 'center',
            value: 'app_version',
            sortable: true,
            width: 182
          },
          {
            text: 'Updated Date/Time',
            align: 'left',
            value: 'update_date',
            sortable: false,
            width: 196
          },
          {
            text: 'Updated By',
            align: 'left',
            value: 'update_by_user',
            sortable: false,
            width: 162
          },
          {
            text: 'Actions',
            align: 'center',
            value: 'action',
            sortable: false,
            width: 82
          }
        ]
      },
      breadcrumbs: [
        {
          text: 'Dashboard',
          exact: true,
          to: '/'
        },
        {
          text: 'Force Update',
          disabled: true,
          link: true,
          to: '/'
        }
      ]
    }
  },
  mounted() {
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle

    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }
  },
  computed: {
    ...mapState('forceUpdate', ['appVersionList', 'isLoading'])
  },
  created() {
    this.fetch()
  },
  methods: {
    dateFmt: date => convertDate(date),
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    hasPermEdit() {
      return true
      // return hasPermision('CanEditPackage')
    },
    rowClick(version) {
      this.$router.push({
        name: 'force-update-edit',
        params: {id: version.device_os}
      })
    },
    ...mapActions('forceUpdate', ['fetch'])
  },

  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.version-table.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 82px;
}
.version-table tr {
  cursor: pointer;
}
</style>
